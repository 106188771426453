$green: #28a745;

// bootstrap overrides
$navbar-padding-y: 20px;
$navbar-padding-x: 0;
$primary: #c3002f;
$success: darken($green, 10%);

// breakpoints
$BREAKPOINT--XX-SMALL: 380px;
$BREAKPOINT--XXX-SMALL: 320px;

// header
$NAV-HEIGHT: 125px;
$NAV-WIDTH: 200px;
$NAV-WIDTH-MEDIUM: 125px;
$NAV-HEIGHT-SMALL: 50px;
$NAV-WIDTH-SMALL: 75px;
