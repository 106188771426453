@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import 'variables';

.nav-height {
    width: $NAV-WIDTH;
    height: $NAV-HEIGHT;
}

.nav-container {
    width: $NAV-WIDTH;
}

@include media-breakpoint-down(md) {

    .nav-height,
    .nav-container {
        width: $NAV-WIDTH-MEDIUM;
    }
}

@include media-breakpoint-down(sm) {
    .nav-height {
        width: $NAV-WIDTH-SMALL;
        height: $NAV-HEIGHT-SMALL;
    }

    .navbar-brand {
        font-size: 16px;
    }

    .navbar-toggler-icon {
        height: 15px;
        width: 15px;
        margin-bottom: .25rem;
    }

    .nav-container {
        width: $NAV-WIDTH-SMALL;
    }
}
