@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import 'variables';

body {
    font-family: 'NissanBrand', sans-serif;
}

// page title
@include media-breakpoint-down(sm) {
    h2 {
        font-size: 1.5rem;
    }
}

.spinner-container {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;

    .spinner-border {
        color: $primary;
        width: 3rem;
        height: 3rem;
    }
}

#overlay {
    position: fixed;
    /* Sit on top of the page content */
    display: none;
    /* Hidden by default */
    width: 100%;
    /* Full width (cover the whole page) */
    height: 100%;
    /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    /* Black background with opacity */
    z-index: 2;
    /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer;
    /* Add a pointer on hover */
}

.log-table {
    font-size: 14px;
}
