@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

//bootstrap overrides
.btn:not(.btn-sm) {
    border-radius: 0;
    font-size: 16px;
    padding: 10px 0;
    min-width: 150px;
    width: 150px;
}
