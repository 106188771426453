// body {
//     padding-bottom: 80px;
// }

// .sticky-footer {
//     position: fixed;
//     bottom: 0;
// }

footer.nav-height {
    max-height: 75px;

    @include media-breakpoint-down(sm) {
        font-size: 14px;
    }
}
