@font-face {
    font-family: 'NissanBrand';
    src: url('../font/NissanBrandW15-Regular.eot?') format('eot'), url('../font/NissanBrandW15-Regular.woff') format('woff'), url('../font/NissanBrandW15-Regular.woff2') format('woff2'), url('../font/NissanBrandW15-Regular.ttf') format('truetype'), url('../font/NissanBrandW15-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'NissanBrandBold';
    src: url('../font/NissanBrandW15-Bold.eot?') format('eot'), url('../font/NissanBrandW15-Bold.woff') format('woff'), url('../font/NissanBrandW15-Bold.woff2') format('woff2'), url('../font/NissanBrandW15-Bold.ttf') format('truetype'), url('../font/NissanBrandW15-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'NissanBrandLight';
    src: url('../font/NissanBrandW15-Light.eot?') format('eot'), url('../font/NissanBrandW15-Light.woff') format('woff'), url('../font/NissanBrandW15-Light.woff2') format('woff2'), url('../font/NissanBrandW15-Light.ttf') format('truetype'), url('../font/NissanBrandW15-Light.otf') format('opentype');
}
